@import url('https://fonts.googleapis.com/css2?family=Righteous&family=Tajawal&display=swap');

*{
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  font-family: 'Tajawal', sans-serif;
}

h1{
  font-family: 'Righteous', cursive;
  color: #5a91cc;
  font-size: 65px;
  padding: 15px;
  margin: 10px;
  text-align: center;
  text-shadow: 0, 0, 5px rgba(0,0,0,.2);
}

h2, h3, h4, h5 {
padding: 15px;
margin: 10px;
}

h2, h3{
  color: #5a91cc;
  font-size: 35px;
  text-align: center;
}

p {
  font-size: 25px;
  padding: 15px;
  margin: 10px;
}

#solutionQuest {
  display: grid;
  align-items: start;
/*  position: absolute;*/
  /*top: 30%;*/

}
#solutionQuest > * {
  grid-column-start: 1;
  grid-row-start: 1;

}

#quest {

  line-height: 70%;
  opacity: 15%;
  text-align: center;
  font-size: 120px;
  margin-top: 20px;
}

li {
  padding: 15px;
  font-size: 20px;
  margin: 10px;
}

.moreInfo {
  display: table;
  width: 100%;
  padding: 20px;
}

.column:not(:last-child) {
  display: table-cell;
  border-right: 5px;
  border-right-style: dotted;
  border-right-color: #5a91cc;
}



#description {
  text-align: center;
}

.wrapper {
  position: relative;
  left: 25%;
  max-width: 100%;
  width: 700px;
  height: 100px;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  /*
  border-style: dotted;
  border-color: #5a91cc
  */
}

#zipcode { /*Styling for wage calc search bar */
  position: relative;
  left: 125px;
  max-width: 100%;
  width: 500px;
  top: 5%;
  height: 75%;
  margin: 10px;
  padding: 10px;
  border-radius: 40px;
  font-size: 25px;
}

/*
.fa-magnifying-glass {
  margin-left: 10px;
}
*/

#wageCalc { /* Style for wage calc button */
  position: absolute;
  width: 55px;
  top: 15%;
  right: 6%;
  height: 58%;
  margin: 10px;
  padding: 10px;
  display: inline-block;
  border-radius: 100px;
  background-color: #5a91cc;
  font-size: 25px;
  text-align: center;
}

button {
  border-radius: 25px;
  padding-left: 10px;
  padding-right: 10px;
}

button:hover {
  background-color: #5a91cc;
  color: white;
}



#hoursWorked {
  display: inline;
}

#hours {
  display: inline;
}

#resultWage {
  position: relative;
  left: 40%;
  font-size: 80px;
  padding: 0px;
  margin: 0, 10px;
  color: #5a91cc;
}

#resultZip {
  position: relative;
  left: 30%;
  font-size: 30px;
  padding: 0px;
  margin: 0, 10px;
  color: #5a91cc;
}


.parent {

}

.child{
  position: relative;
  left: 50px;
  display: inline-block;
  margin: 0px, 40px;
}

.phoneNum {
  position: relative;
  left: 20%;
  font-size: 20px;
  padding: 15px;
  margin: 10px;
}

#phone-number {
  position: relative;
  left: 20%;
}

#savePhone {
  position: relative;
  left: 25%;
  margin: 10px;
  font-size: 20px;
  text-align: center;
  background-color: #5a91cc;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.5s;
  padding: 10px;
}
#phone-zip{
  position: relative;
  left: 20%;
}


#savePhone:after {
  content: '\00bb';
  opacity: 0;
  top: 0;
  right: -15px;
  transition: 0.5s;
  padding: 10px;
}

#savePhone:hover {
  padding-right: 20px;
}

#savePhone:hover:after {
  opacity: 1;
  right: 0;
}

/**************** Styling for Job Listing Page Componenets*************/

.jobFilter {
  position: relative;
  left: 25%;
  max-width: 100%;
  width: 700px;
  height: 100px;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
}


#zipcodeJobs { /*Styling for wage calc search bar */
  position: relative;
  left: 10%;
  max-width: 100%;
  width: 500px;
  top: 5%;
  height: 75%;
  margin: 10, 500px;
  /*margin: 10px;*/
  padding: 10px;
  border-radius: 40px;
  font-size: 25px;
}


#filterJobs{ /*Filter button*/
  position: absolute;
  width: 59px;
  top: 4%;
  right: 16%;
  height: 59px;
  margin: 10px;
  padding: 10px;
  display: inline-block;
  border-radius: 100px;
  background-color: #5a91cc;
  font-size: 25px;
  text-align: center;
}

.availJobs  ul {
  list-style: none;
  height:1000px;
  width:100%;
  line-height: 30px;


}

.availJobs {
  overflow:hidden;
  overflow-y:scroll;
  display: block;
  width: 85%;
  margin: auto;
  border: 5px;
  background-color: #c4dcff30;
}

.job-title {
  font-size: 35px;
  color: black;
  text-align: center;
}

.job-wage, .job-company, .job-loc, .job-link {
  text-align: center;
  line-height: 0px;
}

.formBtn {
  margin-left: 8%;

}

#noneAvail {
  margin: 40px;
  font-size: 20px;
}

.dispJobForm {
  position: relative;
  display: inline;
  left: 20%;
}

#jobFormHeader {
  margin-left: -25%;
}

#popup{
  margin: auto;
  padding-bottom: 40px;
  padding-left: 8%;
  width: 35%;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  background-color: #c4dcff;
  transform: translate(-50%, 4%) scale(0.1);
  transition: transform 0.8s, top 0.8s;
}

#job-title {
  position: relative;
  right:-40px;
}

#job-company{
  position: relative;
  right:-28px;
}
#job-wage {
  position: relative;
  right:-55px;
}
#job-zip {
  position: relative;
  right:-35px;
}
#job-loca {
  position: relative;
  right:-10px;
}
#job-link {
  position: relative;
  right:-43px;
}
