.navbar{
  color: green;
}

.navbar-nav{
  margin: 0;
  padding: 20px;
  list-style-type: none;
  overflow: hidden;
  white-space: nowrap;
}


.nav-item {
  float: left;
  display: inline-block;
  width: 33.;
  max-width: 1200px;
  padding: 5px;
  padding-right: 25px;
  padding-left:25px;

  margin: 0 auto;
  font-size: 24px;
}

.nav-item a {
  align-items: center;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
  font-size: 28px;
  color: #5a91cc;

}

.nav-item:hover {
}


.nav-item a:hover {
  color: black;
  text-decoration: underline;
}
